.page {
    background-color: #050429;
    height: 100%;

}

.mintPage {
    display: flex;
    justify-content: center;
    // flex-direction: row-reverse;
    align-items: center;
    //   flex-wrap: wrap;

}

.logo {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    margin-bottom: 22px;

    img {
        width: 500px;

    }
}

.footer {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.bgImg {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 0;

    img {
        width: 700px;
    }


}

.mintBox {
    background: #0f0d37 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 36px 40px;
    width: 470px;
    height: 450px;
    border: 1px solid rgb(255, 255, 255);
    flex-shrink: 0;
    z-index: 1;


    .priceBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        border-bottom: 1px solid #f2f5f4;

        &>div {
            display: flex;
            flex-direction: column;
        }

        p {
            font: normal normal 600 12px/16px Montserrat;
            color: #f2f5f4;
        }

        h4 {
            font: normal normal bold 18px/24px Montserrat;
            color: #f2f5f4;
        }

        &>div:last-child {
            text-align: right;
        }
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font: normal normal 600 18px/24px Montserrat;
        margin-top: 20px;

        img {
            width: 350px;
            margin: 0px 0 20px;
        }
    }

    .mintAmount {
        width: 100%;
        display: flex;
        background-image: linear-gradient(to bottom, #3e65f5, #94abff);
        border-radius: 10px;
        border: 1px solid rgb(255, 255, 255);


        span {
            width: 62px;
            text-align: center;
            font: normal normal 900 23px/60px Montserrat;
            flex-shrink: 0;
            cursor: pointer;
        }

        .amount {
            width: 100%;
            background-color: #0f0d37;
            text-align: center;
            font: normal normal 900 25px/60px Montserrat;
            color: #ffffff;
        }

        margin-bottom: 20px;
    }

    .mintPrice {
        padding-top: 20px;
        border-top: 1px solid white;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font: normal normal bold 15px/19px Montserrat;
            color: #f2f5f4;

            span {
                font-weight: 600;
                font-family: Montserrat;
            }
        }
    }

    .connectBtn {
        background-image: linear-gradient(to right, #3e65f5, #94abff);
        // background: #85aaeb 0% 0% no-repeat padding-box;
        border-radius: 10px;
        font: normal normal bold 16px/60px Montserrat;
        margin-bottom: 14.5px;
        margin-top: 15px;
        color: white;
        width: 100%;
    }
}

.mintImg {
    z-index: 1;
    margin-left: 1;
    margin-right: 20px;
    position: relative;
    // display:flex;
    width: 470px;
    height: 450px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 30px;

    }


    .badge {
        position: absolute;
        top: 5px;
        left: 17%;
        background: #2e1633;
        border-radius: 50%;
        width: 141px;
        height: 141px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font: normal normal 800 56px/32px Montserrat;
            margin-bottom: 5px;

            span {
                font: normal normal 900 34px/31px Montserrat;
                margin-left: 5px;
            }
        }

        span {
            font: normal normal 900 11px/14px Montserrat;
            text-align: center;
        }
    }
}

@media (max-width: 1200px) {

    .mintPage {
        //   flex-direction: column;
        align-items: center;
    }

    .mintImg {
        width: 450px;
        max-width: 450px;


        img {
            width: 100%;
        }
    }

    .mintBox {
        margin-top: 0;
        max-width: 450px;
        width: 100%;
    }

    .bgImg {
        position: absolute;
        top: 130px;
        left: 10px;
        z-index: 0;

        img {
                    max-width: 450px;

            width: 100%;
        }


    }

    // .mintImg {
    //   margin-left: -90px;
    //   img {
    //     width: 580px;
    //   }
    // }
    // .mintBox {
    //   margin: 50px 0;
    // }
}

@media (max-width: 930px) {

    .mintPage {
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 60px;
    }

    .mintBox {
        background: #0f0d37 0% 0% no-repeat padding-box;
        border-radius: 20px;
    }    
    .mintImg {
        width: 100%;
        height: 390px;
        margin-bottom: 10px;
        margin-top: 5px;
        margin-left: 0;
        margin-right: 0;


        img {
            width: 100%;
            border-radius: 20px;
        }
    }

    .logo {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 10px;


        img {
            width: 330px;

        }


    }
}

/*
@media (max-width: 767px) {



    .mintPage {
        padding: 0 20px;
        background: url(../../assets/img/nft_mint_bg.png) center center no-repeat;
        background-size: cover;
    }


    .mintBox {
        max-width: 346px;
        border-radius: 30px;
        padding: 23px 27px;
        margin-bottom: 80px;

        .priceBox {
            margin-bottom: 22px;

            p {
                font-size: 8px;
                line-height: 10px;
            }

            h4 {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .title {
            font-size: 12px;
            line-height: 15px;

            img {
                width: 250px;
                margin: 10px 0 20px;
            }
        }

        .mintAmount {
            span {
                width: 42.5px;
                font-size: 18px;
                line-height: 32px;
            }

            .amount {
                font-size: 16px;
                line-height: 32px;
            }

            margin-bottom: 20px;
        }

        .mintPrice {
            padding-top: 6px;

            p {
                font-size: 12px;
                line-height: 32px;
            }
        }


        .connectBtn {
            font-size: 11px;
            line-height: 38px;
            margin-bottom: 5px;
            margin-top: 10px;
        }
    }
}
*/