* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'conthrax-sb';
    font-weight: bold;
    src: url('./font/conthrax-sb.otf');
}


@font-face {
    font-family: 'MontserratBlack';
    font-weight: bold;
    src: url('./font/Montserrat-Black.ttf');
}

@font-face {
    font-family: 'MontserratBold';
    font-weight: bold;
    src: url('./font/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    src: url('./font/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'MontserratSemiBold';
    font-weight: 600;
    src: url('./font/Montserrat-SemiBold.ttf');
}

body {
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #050429;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    background-color: #050429;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.bg-toast {
    padding: 25px 35px;
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    cursor: pointer;
    direction: ltr;
    background: #19223b 0% 0% no-repeat padding-box;
    border-radius: 24px;
    width: 100%;
    max-width: 417px;
}

.Toastify__toast-container {
    width: auto !important;
}

@media (max-width: 480px) {
    .bg-toast {
        max-width: 100%;
    }

    .Toastify__toast-container {
        width: 100vw !important;
    }
}