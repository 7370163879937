$mid-width: 1299px;
$sm-width: 663px;
$show-hamburger: 1140px;

.header {
    padding: 32px 30px;
    //  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
        padding: 30px 20px;
    }
}

.headerContent {
    display: flex;
    align-items: center;
    max-width: 1670px;
    width: 100%;
    position: relative;

    &>a>img {
        @media (max-width: $mid-width) {
            width: 100px;
        }

        @media (max-width: 767px) {
            width: 72px;
        }
    }
}

.hamburger {
    cursor: pointer;
    margin: 0 0 0 20px;
    display: none;
    position: relative;
    width: 24px;
    height: 22px;

    @media (max-width: $show-hamburger) {
        display: block;
    }

    span {
        display: block;
        height: 4px;
        border-radius: 4px;
        background-color: #1e3468;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        transition: all ease 0.3s;

        &:nth-child(2) {
            top: calc(50% - 2px);
        }

        &:last-child {
            top: auto;
            bottom: 0;
        }
    }

    &.open {
        span {
            background: white;

            &:first-child {
                transform: rotate(45deg);
                top: 9px;
            }

            &:nth-child(2) {
                display: none;
            }

            &:last-child {
                transform: rotate(-45deg);
                top: 9px;
                bottom: auto;
            }
        }

        @media (max-width: $show-hamburger) {
            position: fixed;
            right: 30px;
            // top: 100px;
            z-index: 1000;
        }
    }

    @media (max-width: $sm-width) {
        height: 19px;
        margin-left: 15px;

        span {
            width: 19px;
            height: 3px;

            &:nth-child(2) {
                top: calc(50% - 1.5px);
            }
        }
    }
}

.socials {
    display: flex;
    align-items: center;
    margin-left: auto;

    a {
        margin-left: 15px;
        margin-right: 10px;

        @media (max-width: $mid-width) and (min-width: 768px) {
            margin-left: 10px;

            img {
                height: 15px;
            }
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .connectBtn {
        margin-left: 7px;
        width: 144px;
        height: 48px;
        font: normal normal 800 14px/18px conthrax-sb;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        background-image: linear-gradient(to right, #7A50F6, #1D3AF5);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        span,
        img {
            font-weight: 600;
            font-family: Montserrat;
            margin-right: 5px;
        }

        @media (max-width: $mid-width) {
            //font: normal normal 800 10px/13px conthrax-sb;
            //width: 105px;
            //height: 40px;

            img {
                height: 23px;
            }
        }

        @media (max-width: $sm-width) {
            width: 110px;
            height: 40px;
            font-size: 12px;
            line-height: 11px;
            margin-left: 4px;

            img {
                height: 18px;
            }
        }

        a {
            @media (max-width: 767px) {
                display: inline;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

.menu {
    margin: 0 auto;

    ul {
        display: flex;
        align-items: center;
        list-style: none;

        li {
            margin-right: 40px;

            a {
                font: normal normal 900 17px/20px MontserratBlack;
                color: #1e3469;
                text-decoration: none;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: $show-hamburger) {
        display: none;
    }
}

@media (max-width: $mid-width) {
    .menu {
        ul {
            padding-left: 20px;

            li {
                margin-right: 20px;

                a {
                    font-size: 16px;
                }
            }
        }
    }
}

.hamburgerMenu {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba($color: #1e3469, $alpha: 0.7);

    @media (max-width: $show-hamburger) {
        .menu {
            display: flex;

            ul {
                width: 100%;
                justify-content: space-around;
            }
        }

        .socials {
            display: none;
        }
    }

    @media (max-width: $show-hamburger) {
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #1e3469;
        padding: 40px 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .menu {
            ul {
                flex-direction: column;
                padding-left: 0;

                li {
                    margin: 20px 0;

                    a {
                        color: white;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .socials {
            display: flex;
            margin-left: 0;

            a {
                display: flex;
            }
        }
    }
}