.toastContainer {
  display: flex;
  align-items: center;
  // width: 417px;

  img {
    margin-right: 25px;
  }
  p {
    font: normal normal 500 17px/27px Montserrat;
    color: white;
  }

  @media (max-width: 767px) {
    img {
      margin-right: 18px;
      width: 40px;
    }
    p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
